import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoalsService } from 'src/app/services/goals.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  ngOnInit() {
    
  }

  

}
