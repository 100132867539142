import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {
  private data: any = {};

  private getRepository(repositoryId) {
    var repository = this.data[repositoryId];
    if (!repository) {
      repository = this.storage.get(`repositories/${repositoryId}`) || {};
      this.data[repositoryId] = repository;
    }
    return repository;
  }

  private saveRepository(repositoryId) {
    var repo = this.getRepository(repositoryId);
    return this.storage.set(`repositories/${repositoryId}`, repo);
  }

  getEntries(repositoryId: string) {
    return this.getRepository(repositoryId);
  }

  getEntry(repositoryId: string, entryId: string) {
    var repo = this.getRepository(repositoryId);
    return repo[entryId];
  }

  saveEntry(repositoryId: string, entryId: string, data: any) {
    var repo = this.getRepository(repositoryId);
    repo[entryId] = data;
    return this.saveRepository(repositoryId);
  }

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
 
  }

  
}
