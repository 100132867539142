import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoalsService } from 'src/app/services/goals.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {
  forms: any;
  goal: any;
  goalId: string;
  state: string;

  constructor(private route: ActivatedRoute, private service: GoalsService) { }

  getFormEntries(goalId, formId) {
    this.state = this.service.getState(goalId);
    var entries = this.service.getFormEntries(goalId, formId);
    return Object.keys(entries).map(k => ({
      id: k,
      data: entries[k]
    }));
  }

  ngOnInit() {
    this.route.parent.data.subscribe(data => {
      this.goalId = this.route.parent.snapshot.params.goalId;
      this.goal = this.service.get(this.goalId);
      this.forms = Object.keys(this.goal.forms).map(k => ({
        id: k,
        entries: this.getFormEntries(this.goalId, k)
      }))
    });
  }

}
