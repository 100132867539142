import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoalsService } from 'src/app/services/goals.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {
  currentStep: any;

  constructor(private route: ActivatedRoute, private service: GoalsService) { }

  ngOnInit() {
    this.route.parent.data.subscribe(data => {
      var goalId = this.route.parent.snapshot.params.goalId;
      this.currentStep = this.service.getNextStep(goalId);
    });
  }

  onSubmit() {
    this.currentStep = this.service.advanceStep(this.currentStep);
  }

}
