import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RepositoryService } from './repository.service';
import { NotificationService } from './notification.service';

@Injectable({ providedIn: 'root' })
export class GoalsService {
  advanceStep(step: any) {
    var goalId = step.goalId;
    var state = this.getState(goalId);
    if (step.type == 'form') {
      this.saveForm(goalId, step.form.id, step.form.entryId, step.form.data);
    }
    state.nextStep = step.next;
    this.saveState(goalId, state);
    return this.getNextStep(goalId)
  }
  getState(goalId: any) {
    return this.repo.getEntry(`${goalId}`, 'state') || {};
  }
  private saveState(goalId: string, state: any) {
    return this.repo.saveEntry(`${goalId}`, 'state', state);
  }

  getNextStep(goalId: any) {
    var goal = this.get(goalId);
    var state = this.getState(goalId);
    var nextStep = 'start';
    if (state.nextStep) {
      nextStep = state.nextStep;
    }
    return this.getStep(goalId, goal, nextStep);
  }

  private getStep(goalId, goal, stepName: string) {
    var step = {
      ...goal.steps[stepName],
      goalId, 
      id: stepName
    };
    if (step.type === 'form') {
      step.form = this.getForm(goalId, step.form, null)
    }
    else if (step.type === 'reminder') {
      const base = 'http://www.google.com/calendar/event';
      const start = '20191031T130000Z';
      const end = '20191031T140000Z';
      const title = '';
      const location = '';
      const body = '';
      const url = `${base}?action=TEMPLATE&dates=${start}%2F${end}&text=${title}&location=${location}&details=${body}`;
      step.googleCalendar = url;
    }
    return step;
  }

  getSteps(goalId: any): any {
    var goal = this.get(goalId);
    var steps = [];
    var nextStep = 'start';
    var index = 0;
    do {
      var step = this.getStep(goalId, goal, nextStep);
      step.isFirst = index === 0;
      step.isLast = nextStep === undefined;
      steps.push(step);
      nextStep = step.next;
      index++;
    }
    while (nextStep);
    return steps;
  }
  constructor(private repo: RepositoryService, private notification: NotificationService) {

  }
  saveForm(goalId: string, formId: string, entryId: string, data: any) {
    this.repo.saveEntry(`${goalId}/forms/${formId}`, entryId, data);
    this.notification.info(`Saved successfully`);
  }
  getFormCurrent(goalId: string, formId: string) {
    return this.getForm(goalId, formId, null);
  }

  private getFormEntry(scope: string) {
    switch (scope) {
      case 'global': return scope;
      case 'byDay':
        var today = new Date();
        var dd = String(today.getDate());
        var mm = String(today.getMonth() + 1);
        var yyyy = today.getFullYear();
        return mm + '/' + dd + '/' + yyyy;
    }
  }

  getFormEntries(goalId: string, formId: string) {
    var entries = this.repo.getEntries(`${goalId}/forms/${formId}`);
    return entries;
  }

  getForm(goalId: string, formId: string, entryId: string): any {
    var goal = this.get(goalId);
    var form = goal.forms[formId];
    if (entryId === null) {
      entryId = this.getFormEntry(form.scope);
    }

    var entry = this.repo.getEntry(`${goalId}/forms/${formId}`, entryId) || {};
    return {
      id: formId,
      entryId: entryId,
      schema: { ...form.schema },
      data: { ...entry },
      framework: 'material-design'
    };
  }
  getAllGoals() {
    const allGoals = [calorieCount, readBook]
    return allGoals.map(g => ({
      title: g.title,
      icon: g.icon,
      id: g.id,
      visualization: g.visualization,
    }));
  }
  get(id) {
    switch (id) {
      case 'caloriecount': return calorieCount;
      case 'readbook': return readBook;
      default: throw 'not implemented'
    }
  }
}

@Injectable({ providedIn: 'root' })
export class GoalResolver implements Resolve<any> {
  constructor(private service: GoalsService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    return this.service.get(route.paramMap.get('goalId'));
  }
}

var readBook = {
  "title": "Read a Book",
  "id": "readbook",
  "icon": "menu_book",
  "visualization": {
    "value": 25,
    "title": "Complete"
  },
  "forms": {
    "configuration": {
      "scope": "global",
      "schema": {
        "type": "object",
        "properties": {
          "bookTitle": { "type": "string" },
          "numberOfPages": { "type": "number" }
        }
      }
    },
    "pageEntry": {
      "scope": "byDay",
      "schema": {
        "type": "object",
        "properties": {
          "currentPage": { "type": "number" }
        }
      }
    }
  },
  "steps": {
    "start": {
      "title": "Select your book",
      "description": "Enter your book so we can start tracking it for you",
      "type": "form",
      "form": "configuration",
      "next": "reminder"
    },
    "reminder": {
      "title": "Set a reminder",
      "description": "Set a reminder for the next time you will read",
      "type": "reminder",
      "next": "pageEntry"
    },
    "pageEntry": {
      "title": "What page are you on?",
      "description": "Enter the current page you are on to track your progress",
      "type": "form",
      "form": "pageEntry"
    }
  }
};
var calorieCount = {
  "title": "Lose Weight",
  "id": "caloriecount",
  "icon": "fitness_center",
  "visualization": {
    "value": 73,
    "title": "To Target Weight"
  },
  "forms": {
    "configuration": {
      "scope": "global",
      "schema": {
        "type": "object",
        "properties": {
          "weight": { "type": "number" },
          "height": { "type": "number" },
          "gender": { "type": "string", "enum": ["Female", "Male"] }
        },
        "required": ["weight", "height", "gender"]
      }
    },
    "daily": {
      "scope": "byDay",
      "schema": {
        "type": "object",
        "properties": {
          "calorieEntries": {
            "type": "array",
            "items": {
              "type": "object",
              "properties": {
                "calories": { "type": "number" },
                "note": { "type": "string" }
              }
            },
            "required": ["calories", "notes"]
          }
        }
      }
    }
  },
  "steps": {
    "start": {
      "title": "Enter your information",
      "description": "The first step is to get basic information on you.  This is used to calculate your daily target calories.",
      "type": "form",
      "form": "configuration",
      "next": "reminder"
    },
    "reminder": {
      "title": "Schedule a reminder",
      "description": "Schedule a reminder for the next time to enter calories",
      "type": "reminder",
      "next": "daily"
    },
    "daily": {
      "title": "Enter your daily calories",
      "description": "Everyday, record the amount of calories you consume.  You can create multiple entries for each day to organize your entries.  We recommend to start by creating one entry for each meal to start. ToDo will track your entries across days and allow you to quickly view your progress.",
      "type": "form",
      "form": "daily",
      "next": "reminder"
    }
  }
};