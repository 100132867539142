import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GoalsComponent } from './goals/goals.component';
import { GoalResolver } from './services/goals.service';
import { FormComponent } from './goals/form/form.component';
import { StepsComponent } from './goals/steps/steps.component';
import { DataComponent } from './goals/data/data.component';
import { OverviewComponent } from './goals/overview/overview.component';
import { GetstartedComponent } from './goals/getstarted/getstarted.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { 
    path: 'goals/:goalId', 
    resolve: {
      goal: GoalResolver,
    },
    component: GoalsComponent,
    children: [
      { path: '', component: OverviewComponent },
      { path: 'step', component: StepsComponent },
      { path: 'data', component: DataComponent },
      { path: 'getstarted', component: GetstartedComponent },
      { path: 'form/:formId/entries/:entryId', component: FormComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
