import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private OPEN_DURATION = 5000;
  private MESSAGE_DISMISS;

  constructor(private _snackBar: MatSnackBar) { }

  info(message: string) {
    this._snackBar.open(message, this.MESSAGE_DISMISS, {
      duration: this.OPEN_DURATION,
    });
  }
}
