import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoalsService } from 'src/app/services/goals.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  form: any;

  constructor(private route: ActivatedRoute, private service: GoalsService) { }

  ngOnInit() {
    this.route.parent.data.subscribe(data => {
      var goalId = this.route.parent.snapshot.params.goalId;
      var formId = this.route.snapshot.params.formId;
      var entryId = this.route.snapshot.params.entryId;
      this.form = this.service.getForm(goalId, formId, entryId);
    });
  }

  onSubmit() {
    var goalId = this.route.parent.snapshot.params.goalId;
    var formId = this.route.snapshot.params.formId;
    var entryId = this.route.snapshot.params.entryId;
    return this.service.saveForm(goalId, formId, entryId, this.form.data);
  }

}
