import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoalsService } from 'src/app/services/goals.service';

@Component({
  selector: 'app-getstarted',
  templateUrl: './getstarted.component.html',
  styleUrls: ['./getstarted.component.scss']
})
export class GetstartedComponent implements OnInit {

  steps: any;

  constructor(private route: ActivatedRoute, private service: GoalsService) { }

  ngOnInit() {
    this.route.parent.data.subscribe(data => {
      var goalId = this.route.parent.snapshot.params.goalId;
      this.steps = this.service.getSteps(goalId);
    });
  }

}
