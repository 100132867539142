import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})
export class GoalsComponent implements OnInit {
  goal: any;
  links: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.goal = data.goal;
      var goalId = this.route.snapshot.params.goalId;
      this.links = [
        { path: `../${goalId}`, label: 'Overview' },
        { path: `../${goalId}/steps`, label: 'All Steps' },
        { path: `../${goalId}/getstarted`, label: 'Get Started' },
        { path: `../${goalId}/data`, label: 'Data' }
      ]
    });
  }

}
